import React from "react";
import { Box } from "@mui/material";


const FeedsAndPodcasts = () => {
  return (
    <>
      <Box style={{ position: 'relative' }} className="feeds">
        <img alt="main-photo" src="images/generalImages/2.png" style={{ width: "100%", height: "100%" }} className="main-photo" />
      </Box>
    </>
  );
};
export default FeedsAndPodcasts;
