const FAQ = [
  "What are the latest Ferrari models released in 2024?",
  "Where can I find the specifications for the Ferrari SF90 Stradale?",
  "How can I book a test drive for a Ferrari?",
  "What are the key differences between Ferrari Roma and Ferrari Portofino?",
  "Where can I watch Ferrari Formula 1 races live?",
  "What are the upcoming Ferrari events and track days?",
  "How can I purchase official Ferrari merchandise?",
  "What is the top speed of the Ferrari 812 Superfast?",
  "How do I join the Ferrari Owners Club?",
  "What are the latest updates from the Ferrari F1 Team?",
  "Where can I find a Ferrari dealership near me?",
  "What are the available financing options for buying a Ferrari?",
  "What safety features are included in the latest Ferrari models?",
  "Can I customize my Ferrari before purchasing?",
  "Where can I find detailed reviews and comparisons of Ferrari models?",
  "What is the fuel efficiency of Ferrari’s hybrid cars?",
  "How can I stay updated with Ferrari racing news and events?",
  "What are the environmental sustainability initiatives taken by Ferrari?",
  "Where can I learn about the history and legacy of Ferrari?",
  "What are the maintenance costs for owning a Ferrari?",
];


export const getRandomPrompts = () => {
    let shuffled = FAQ.sort(() => 0.5 - Math.random());
    // Get sub-array of first n elements after shuffled
    return shuffled.slice(0, 4);
}
